module.exports = {
  comm: {
    home: 'Ana Sayfa',
    pricing: 'Fiyatlandırma',
    blog: 'Ses Klonlama ve API',
    language: 'dil:',
    select_language: 'dil seçin',
    login: 'Giriş Yap',
    login_desc: 'TikTok Ses Oluşturucu hesabınıza erişmek için giriş yapın',
    logout: 'Çıkış Yap',
    select_language: 'Dil seçin',
    txt2voice: 'Metin ile Ses',
    voice2txt: 'Ses ile Metin',
    voiceclone: 'Ses Klonlama',
    video2txt: 'Video ile Metin',
    footer_help: 'Yardıma ihtiyacınız var mı? Bize e-posta gönderin:',
    footer_mail: 'tiktokaivoicetool@gmail.com'
  },
  txt2voice: {
    title: 'TikTok Ses Oluşturucu: Eğlenceli AI TikTok Sesleri Üretin',
    description: 'Eğlenceli AI TikTok sesleri ücretsiz olarak oluşturun ve indirin, örneğin Jessie sesi, C3PO sesi, ghostface sesi...',
    keywords: 'TikTok Ses Oluşturucu, AI TikTok Sesi, Tik Tok Ses Oluşturucu',
    main_title_p01: 'TikTok Ses Oluşturucu',
    main_title_p02: 'Eğlenceli TikTok Sesleri Üretin: Jessie sesi, C3PO sesi, ghostface sesi',
    main_textarea_holder: 'Metni buraya yazın veya yapıştırın',
    main_genvoice: 'oluştur',
    main_generating: 'oluşturuluyor',
    main_input_empty: 'lütfen metni yazın veya yapıştırın',
    daily_usage_limit_msg: '1) Günlük maksimum kullanım limiti aşıldı. Lütfen yarın tekrar deneyin. 2) Acil olarak daha fazla kullanım ihtiyacınız varsa, lütfen bize e-posta gönderin.',
    text_max_prompt1: 'Lütfen kelime sayısını şu kadarla sınırlayın',
    text_max_prompt2: 'kelime veya daha az!',
    popup_nologin_title: 'Abonelik planınızı yükseltin',
    popup_nologin_desc: 'Premium aboneliğe yükselerek daha fazla ses oluşturma süresi açın.',
    popup_nologin_btntext: 'Abonelik planlarını keşfedin',
    popup_nosub_title: 'Abonelik planınızı yükseltin',
    popup_nosub_desc: 'Premium aboneliğe yükselerek daha fazla ses oluşturma süresi açın.',
    popup_nosub_btntext: 'Abonelik planlarını keşfedin',
    popup_sublimit_title: 'Abonelik süresi doldu, lütfen yükseltin',
    popup_sublimit_desc: 'Premium özelliklerimiz ve sınırsız erişimle deneyiminizi geliştirin.',
    popup_sublimit_btntext: 'Daha fazla satın almaya gidin'
  },
  pricing: {
    new_features_alert: "📣 Ücretli üyeler için 🎙️ Ses Klonlama ve 🤖 API hizmetlerini başlattık! (Ödeme sonrası, bu özellikler için yardım almak üzere tiktokaivoicetool@gmail.com adresine e-posta gönderin). Ayrıca, herhangi bir özelleştirme isteği için bize e-posta göndermekten çekinmeyin😃",
    pricing_title: 'Bireysel içerik üreticilerinden en büyük işletmelere kadar, sizin için bir planımız var.',
    pricing_desc: '100+ doğal, insan benzeri ses. 15+ dil. ve neredeyse herkes için en düşük fiyatlandırma',
    sub_free_name: 'Ücretsiz',
    sub_free_desc: 'En gelişmiş AI sesini denemek isteyen bireyler için',
    sub_free_content: [
      'Ayda 1000 karakter sınırı',
      'Günde 5 oluşturma',
    ],
    sub_starter_name: 'Başlangıç',
    sub_starter_desc: 'Küresel bir izleyici kitlesi için premium içerik üreten içerik üreticileri için',
    sub_starter_content: [
      '1 ses klonu',
      'Ayda 300.000 karakter sınırı (yaklaşık 7 saat ses)',
      'Sınırsız indirme',
      '72 saat içinde e-posta desteği',
      'Yeni seslere ve özelliklere öncelikli erişim',
    ],
    sub_pro_name: 'Pro',
    sub_pro_desc: 'İçerik üretimini genişleten içerik üreticileri için',
    sub_pro_content: [
      'API erişimi',
      '3 ses klonu',
      'Ayda 1.000.000 karakter sınırı (yaklaşık 24 saat ses)',
      'Sınırsız indirme',
      '48 saat içinde e-posta desteği',
      'Yeni seslere ve özelliklere öncelikli erişim',
    ],
    period_year: 'Yıllık',
    period_month: 'Aylık',
    period_month_short: 'ay',
    billed_year: 'Yıllık faturalandırılır',
    most_popular: 'En popüler',
    discount_quantity: '$48 tasarruf edin',
    buy_btntext: 'Başla',
    pay_succ: 'Ödeme başarılı!',
    pay_succ_desc: 'Satın aldığınız için teşekkürler. İşleminiz başarıyla işleme alındı.',
    pay_succ_btntext: 'TikTok Voice kullanmaya başlayın',
    pay_fail: 'Ödeme başarısız!',
    pay_fail_desc: "Üzgünüz, işleminizi işleme alamadık. Lütfen tekrar deneyin veya destek ekibimizle iletişime geçin (tiktokaivoicetool@gmail.com).",
    pay_fail_btntext: 'Ana sayfaya dön'
  },
  setting: {
    setting: 'Ayarlar',
    setting_title: 'Hesabınızı, kullanımınızı ve aboneliğinizi burada yönetin.',
    basic_info: 'Temel Bilgiler',
    user_name: 'Ad',
    user_email: 'E-posta',
    user_account: 'Hesap',
    user_subscript: 'Mevcut plan',
    user_usage: 'Kullanım',
    manage_subscript: 'Aboneliği yönet',
    use_limit_promote: "10000 karakterlik aylık limitinizin 5000'ini kullandınız.",
    not_logged_in: 'Giriş yapmadınız',
    go_to_login: 'Giriş sayfasına git >>'
  },
  login: {
    title: "TikTok Ses Üretici",
    subtitle: "TikTok Ses Üretici hesabınıza erişmek için giriş yapın",
    alreadyLoggedIn: "Zaten giriş yaptınız",
    goToHome: "Ana sayfaya git"
  },
  faq: {
    quest1: 'TikTok Ses Oluşturucu - En İyi AI TikTok Ses Aracı',
    answer1_1: 'Bu, en son tts TikTok teknolojisi tarafından geliştirilen bir TikTok AI ses oluşturucu aracıdır, Jessie sesi (kadın sesi), Siri sesi, ghostface sesi, C3PO sesi (robot sesi), derin ses (anlatıcı), Skye sesi, sıcak ses, Bestie sesi, Kahraman sesi (Chiris sesi), empatik ses, ciddi ses, Joey sesi, Stitch sesi, Stormtrooper sesi (Star Wars), Rocket sesi (Guardians of the Galaxy) gibi TikTok seslerini oluşturabilir.',
    answer1_2: 'Yakında desteklenecek AI TikTok sesleri arasında: Derek sesi, Trickster sesi, Austin Butler sesi, haber spikeri sesi, Adam sesi, köpek sesi, Miley Cyrus sesi, Alexa sesi, uzaylı sesi, hayvan sesi, bebek sesi, bilgisayar sesi, sincap sesi, yankı sesi, npr sesi.',
    answer1_3: 'Ayrıca, TikTok ses oluşturucu aracı Çince, Japonca, Korece, Vietnamca, Tayca, Hintçe, Farsça, Rusça, Almanca, Fransızca, Romence, Çekçe, İspanyolca, Portekizce, Bengalce, İtalyanca, Arapça, Urduca, Geleneksel Çince ve Malayca dahil olmak üzere birçok diğer dili destekler.',
    answer1_4: 'Acil olarak belirli bir sese ihtiyacınız varsa, lütfen bana e-posta gönderin.',

quest2: "TikTok Ses Oluşturucu'nun avantajları nelerdir?",
    answer2_1: '- TikTok ses oluşturucu aracı, TikTok videolarında sıklıkla kullanılan çeşitli ses türlerini oluşturabilir.',
    answer2_2: '- Bu, insan benzeri tiktok tts sesleri üretebilen en yeni AI ses oluşturudur.',
    answer2_3: "- PC'de video düzenleme için daha kullanışlıdır.",
    answer2_4: '- Şu anda TikTok tts uygulamasında bulunmayan bazı sesleri kullanabilirsiniz.',

quest3: 'TikTok Ses Oluşturucu nasıl kullanılır?',
    answer3_1: 'TikTok ses oluşturucu çok kolay kullanılır:',
    answer3_2: '- Sesin dilini ve aksanını seçin.',
    answer3_3: '- Metni sese dönüştürmek için giriş kutusuna yazın.',
    answer3_4: '- Oluştur düğmesine basın ve birkaç saniye bekleyin.',
    answer3_5: '- AI TikTok sesini oynatın veya indirin.',

quest4: 'En ünlü ve eğlenceli TikTok sesleri nelerdir?',
    answer4_1: 'En ünlü erkek TikTok sesi, resmi olarak anlatıcı olarak adlandırılan derin sestir.',
    answer4_2: 'En eğlenceli TikTok sesi için, kişisel olarak ghostface sesi ve C3PO sesini öneririm. Bu sesler, TikTok videolarını seslendirmek için sıklıkla kullanılır, çünkü çok kolay tanınır ve hızlı bir şekilde izleyicileri çekebilir.',

quest5: 'En rahatsız edici TikTok sesleri nelerdir?',
    answer5_1: 'Gerçekten "rahatsız edici" bir TikTok sesi olduğunu düşünmüyorum.',
    answer5_2: "Bu, sesin kullanıldığı bağlama ve içeriğe daha çok bağlıdır, bu da bazı insanları rahatsız edebilir. Örneğin, bazı insanlar Jessie sesini, belirli bir süre boyunca TikTok'ta çok sık ortaya çıktığı için rahatsız edici bulabilir. Bununla birlikte, tonu ve kalitesi nedeniyle, aslında harika bir TikTok ses efektidir. Ayrıca, arkasındaki TikTok seslendirme sanatçısı çok profesyoneldir ve TikTok'ta kendi videolarını da yayınlar.",
    answer5_3: 'Bu nedenle, herkese endişelerini ve önyargılarını bir kenara bırakmalarını, bu araçtaki sesleri birkaç kez dinlemelerini ve sabırla kendilerine en uygun TikTok ses efektini bulmalarını öneririm.',

quest6: 'TikTok Ses Oluşturucu kullanarak Jessie sesini nasıl oluşturabilirim?',
    answer6_1: '- TikTok ses oluşturucu aracının ilk açılır menüsünde English (US) seçin, ardından diğer açılır menüde Jessie sesi (kadın sesi) seçin.',
    answer6_2: '- Giriş kutusuna metni yazın ve oluştur düğmesine tıklayın.',
    answer6_3: '- Birkaç saniye veya on saniyeye kadar bekleyin, AI sesini duyacaksınız. Araç çubuğunda, aşağı ok olan düğmeyi bulun ve indirmek için tıklayın.',

quest7: 'TikTok Ses Oluşturucu kullanarak C3PO sesini nasıl oluşturabilirim?',
    answer7_1: '- TikTok ses oluşturucu aracının ilk açılır menüsünde English (US) seçin, ardından diğer açılır menüde C3PO sesi (robot sesi) seçin.',
    answer7_2: '- Giriş kutusuna metni yazın ve oluştur düğmesine tıklayın.',
    answer7_3: '- Birkaç saniye veya on saniyeye kadar bekleyin, AI sesini duyacaksınız. Araç çubuğunda, aşağı ok olan düğmeyi bulun ve indirmek için tıklayın.',

quest8: 'TikTok Ses Oluşturucu kullanarak ghostface sesini nasıl oluşturabilirim?',
    answer8_1: '- TikTok ses oluşturucu aracının ilk açılır menüsünde English (US) seçin, ardından diğer açılır menüde ghostface sesini seçin.',
    answer8_2: '- Giriş kutusuna metni yazın ve oluştur düğmesine tıklayın.',
    answer8_3: '- Birkaç saniye veya on saniyeye kadar bekleyin, AI sesini duyacaksınız. Araç çubuğunda, aşağı ok olan düğmeyi bulun ve indirmek için tıklayın.',

quest9: 'TikTok Ses Oluşturucu kullanarak siri sesini nasıl oluşturabilirim?',
    answer9_1: '- TikTok ses oluşturucu aracının ilk açılır menüsünde English (US) seçin, ardından diğer açılır menüde siri sesini seçin.',
    answer9_2: '- Giriş kutusuna metni yazın ve oluştur düğmesine tıklayın.',
    answer9_3: '- Birkaç saniye veya on saniyeye kadar bekleyin, AI sesini duyacaksınız. Araç çubuğunda, aşağı ok olan düğmeyi bulun ve indirmek için tıklayın.',

quest10: 'TikTok videosuna TikTok ses efektleri nasıl eklenir?',
    answer10_1: "TikTok'ta resmi TikTok seslerini nasıl kullanacağınızı öğrenmek istiyorsanız, yakında bu konuda özel bir blog yazısı yazacağım.",
    answer10_2: "Burada, TikTok ses oluşturucudan oluşturduktan ve indirdikten sonra sesinizi TikTok'a nasıl yükleyeceğinizi açıklayacağım.",
    answer10_3: "1. PC'nizde TikTok sesini oluşturduysanız ve bunu TikTok veya telefonunuzdaki başka bir video düzenleme uygulamasına yüklemek istiyorsanız, ses dosyasını telefonunuza aktarmanız gerekir. iPhone'lar için, airDrop kullanarak aktarabilirsiniz. Android telefonlar için, bu süreçle çok aşina değilim, ancak bunu yapmak için kesinlikle yöntemler ve araçlar bulacaksınız.",
    answer10_4: "2. Ses dosyası telefonunuza aktarıldıktan sonra, TikTok'u açabilirsiniz:",
    answer10_5: '- Arayüzün altındaki "+" düğmesine dokunun, telefonunuzdaki videoyu seçin.',
    answer10_6: '- Videoyu yükledikten sonra, düzenleme sayfasına girmek için ekranın sağ tarafında bir kare simgesi bulun.',
    answer10_7: '- Sayfanın alt kısmında "ses ekle" düğmesini bulun ve yeni aktarılan ses dosyasını seçmek için dokunun.',
    answer10_8: '- Bu arayüzde, videoya bazı ayarlamalar yapabilirsiniz, ardından sağ üst köşedeki düğmeye dokunarak yayınlayın.',

quest11: 'TikTok Voice ücretsiz mi?',
    answer11: 'Evet, bu metinden sese ücretsiz bir TikTok AI ses oluşturucu aracıdır.',

quest12: 'TikTok videomda popüler TikTok sesini nerede oluşturabilirim?',
    answer12: 'Videonuza AI TikTok sesi eklemek için https://tiktokvoice.net/ adresini ziyaret edebilirsiniz.'
  },
  // host: 'http://tiktokvoice.net'
  host: 'https://tiktokvoice.net'
}